<!-- 支付订单统计 -->
<template>
  <div style="padding: 20px">
    <!-- 查询搜索表单 -->
    <el-form :inline="true" :model="queryForm" class="query-form-inline">
      <el-form-item item label="开发者名称">
        <el-input
          v-model="queryForm.developer_name"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item item label="APPID">
        <el-input v-model="queryForm.appid" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item item label="应用名称">
        <el-input v-model="queryForm.app_name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="统计日期（UTC）">
        <el-date-picker
          v-model="queryForm.date_range"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onQuery">查询</el-button>
        <el-button @click="onClear">清空</el-button>
      </el-form-item>
    </el-form>
    <div style="margin-bottom: 20px;">
      <el-button
        :loading="exportLoading"
        type="primary"
        size="default"
        @click="handleExportExcel"
        >导出Excel</el-button
      >
    </div>
    <!-- 表格 -->
    <el-table :data="list" border stripe>
      <el-table-column prop="no" label="序号"> </el-table-column>
      <el-table-column prop="developer_name" label="开发者名称">
      </el-table-column>
      <el-table-column prop="appid" label="APPID"></el-table-column>
      <el-table-column prop="app_name" label="应用名称"></el-table-column>
      <el-table-column prop="statistic_date" label="统计时间"></el-table-column>
      <!-- 用户付款 -->
      <el-table-column label="用户付款">
        <el-table-column prop="user_payer.payer_user_count" label="付款用户数">
        </el-table-column>
        <el-table-column prop="user_payer.payer_times" label="付款次数">
        </el-table-column>
        <el-table-column prop="user_payer.payer_bsv_amount" label="BSV付款总额">
        </el-table-column>
        <el-table-column prop="user_payer.payer_btc_amount" label="BTC付款总额">
        </el-table-column>
        <el-table-column prop="user_payer.payer_eth_amount" label="ETH付款总额">
        </el-table-column>
      </el-table-column>
      <!-- 开发者付款 -->
      <el-table-column label="开发者付款">
        <el-table-column prop="developer_payer.payer_times" label="付款次数">
        </el-table-column>
        <el-table-column
          prop="developer_payer.payer_bsv_amount"
          label="BSV付款总额"
        >
        </el-table-column>
        <el-table-column
          prop="developer_payer.payer_btc_amount"
          label="BTC付款总额"
        >
        </el-table-column>
        <el-table-column
          prop="developer_payer.payer_eth_amount"
          label="ETH付款总额"
        >
        </el-table-column>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getOrderPaymentStat } from "@/api/developer.js";
import { export_json_to_excel } from "@/utils/export2excel";
const EXPORT_PAGE_SIZE = 100;
const hour2Second = (hour) => hour * 60 * 60;

// getOrderPaymentStat
export default {
  data() {
    return {
      queryForm: {
        developer_name: "",
        appid: "",
        app_name: "",
        date_range: "",
      },
      page: 1,
      pageSize: 20,
      total: 0,
      list: [],
      exportLoading: false,
    };
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    async fetchList() {
      try {
        const params = this.generateQueryParams();
        const { data } = await getOrderPaymentStat(params);
        this.list = data.data.list || [];
        this.total = data.data.total || 0;
      } catch (error) {
        console.log(error);
        this.list = [];
        this.total = 0;
      }
    },
    onQuery() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchList();
    },
    onClear() {
      this.queryForm = {
        developer_name: "",
        appid: "",
        app_name: "",
        date_range: "",
      };
      this.page = 1;
      this.pageSize = 20;
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchList();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchList();
    },
    handleStatusChange() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchList();
    },
    // 生成查询参数
    generateQueryParams() {
      const { date_range, ..._params } = this.queryForm;
      const params = {
        ..._params,
        page: this.page,
        page_size: this.pageSize,
      };

      // 开始时间和结束时间
      if (Array.isArray(date_range)) {
        const start_at = Math.floor(date_range[0] / 1000);
        let end_at = Math.floor(date_range[1] / 1000);

        // 结束时间默认加 60 * 60 * 24 秒;
        end_at += hour2Second(24);

        params.start_at = start_at + hour2Second(8);
        params.end_at = end_at + hour2Second(8);
      }
      return params;
    },
    async fetchExportData() {
      let exportData = [];
      try {
        const params = this.generateQueryParams();
        const { data } = await getOrderPaymentStat({
          ...params,
          page: 1,
          page_size: EXPORT_PAGE_SIZE,
        });
        exportData = data.data.list || [];
        const total = data.data.total || 0;
        // 不需要请求多个接口返回所有数据
        if (total <= EXPORT_PAGE_SIZE) {
          return exportData;
        } else {
          // 请求多个接口查询所有数据
          const totalPage = Math.ceil(total / EXPORT_PAGE_SIZE);

          const fetchPromiseList = [];
          for (let i = 2; i <= totalPage; i++) {
            fetchPromiseList.push(
              getOrderPaymentStat({
                ...params,
                page: i,
                page_size: EXPORT_PAGE_SIZE,
              })
            );
          }

          const responseList = await Promise.all(fetchPromiseList);
          responseList.forEach((response) => {
            const list =
              (response &&
                response.data &&
                response.data.data &&
                response.data.data.list) ||
              [];
            exportData = [...exportData, ...list];
          });
          return exportData;
        }
      } catch (error) {
        return [];
      }
    },
    async handleExportExcel() {
      if (this.exportLoading) {
        return;
      }
      this.exportLoading = true;
      const exportData = await this.fetchExportData();
      const data = exportData.map((item) => {
        return [
          item.no,
          item.developer_name,
          item.appid,
          item.app_name,
          item.statistic_date,
          item.user_payer.payer_user_count,
          item.user_payer.payer_times,
          item.user_payer.payer_bsv_amount,
          item.user_payer.payer_btc_amount,
          item.user_payer.payer_eth_amount,
          item.developer_payer.payer_times,
          item.developer_payer.payer_bsv_amount,
          item.developer_payer.payer_btc_amount,
          item.developer_payer.payer_eth_amount,
        ];
      });
      export_json_to_excel({
        header: [
          "序号",
          "开发者名称",
          "APPID",
          "应用名称",
          "统计时间",
          "用户-付款用户数",
          "用户-付款次数",
          "用户-BSV付款总额",
          "用户-BTC付款总额",
          "用户-ETH付款总额",
          "开发者-付款次数",
          "开发者-BSV付款总额",
          "开发者-BTC付款总额",
          "开发者-ETH付款总额",
        ],
        data: data, //具体数据 必填
        filename: "应用支付统计",
        autoWidth: true, //非必填
        bookType: "xlsx", //非必填
      });
      this.exportLoading = false;
    },
  },
};
</script>

<style lang="less" scoped></style>
